import { Grid, Switch, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useStyles } from "./ViewSwitch.styles";
import { ChartView } from '../data/enums';

interface ViewSwitchProps {
	view: ChartView;
	onClick: () => void;
}

const GreySwitch = withStyles({
	switchBase: {
		color: grey[300],
		'&$checked': {
			color: grey[300],
		},
		'&$checked + $track': {
			backgroundColor: grey[800],
		},
	},
	checked: {},
	track: {},
})(Switch);

const ViewSwitch = (props: ViewSwitchProps) => {

	const classes = useStyles();
	const { view, onClick } = props;

	return (
		<Typography className={classes.switchText} component="div">
			<Grid component="label" container alignItems="center" spacing={1}>
				<Grid item>
					<Typography component="span" style={{ color: view === ChartView.Doctors ? '#FFB03B' : '#666666' }}>Doctors</Typography>
				</Grid>
				<Grid item>
					<GreySwitch checked={view !== ChartView.Doctors} onChange={onClick} />
				</Grid>
				<Grid item>
					<Typography component="span" style={{ color: view === ChartView.Hospitals ? '#FFB03B' : '#666666' }}>Hospitals</Typography>
				</Grid>
			</Grid>
		</Typography>
	);
}

export default ViewSwitch;
