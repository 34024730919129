import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Typography } from "@material-ui/core";

import { useStyles } from './DoctorModal.styles';
import { ReactComponent as Logo } from './assets/CureWheel.svg';
import { IDiagnosis, IDoctor } from "../data/DiagnosisData";
import { CancelScheduleSendSharp } from "@material-ui/icons";

interface DoctorModalProps {
	open: boolean;
	onClose: () => void;
	doctor: IDoctor;
	diagnosis: IDiagnosis
}

const DoctorModal = (props: DoctorModalProps) => {

	const classes = useStyles();
	const { open, onClose, doctor, diagnosis } = props;
	const docName = `${doctor.firstName} ${doctor.lastName}`.trim();

	const getTrialPercent = () => {

		console.log(diagnosis);

		const percent = Math.floor(100 - (doctor.trialScore * 100));

		return percent || 1;
	};

	const getPubPercent = () => {

		const percent = Math.floor(100 - (doctor.publicationScore * 100));

		return percent || 1;
	};

	const PrimaryContent = () => {

		const trialText = doctor.trialCount > 0 ? `has led ${doctor.trialCount} Clinical Trial(s)` : '';
		const pubText = doctor.publicationCount > 0 ? `published at least ${doctor.publicationCount} article(s) with significant peer-reviewed citations` : '';
		const midText = !!trialText && !!pubText ? 'as well as' : !!pubText ? 'has' : '';
		const docText = `${docName} ${trialText} ${midText} ${pubText} that the NLM found were relevant to the diagnosis of Pediatric ${diagnosis.name}`.trim();

		return (
			<Typography className={classes.primaryContent}>
				According to keyword search results from the <span className={classes.emphasis}>U.S. National Library of Medicine (NLM)</span>, {docText}.
			</Typography>
		);
	};

	const SecondaryContent = () => {

		const trialPercent = getTrialPercent();
		const pubPercent = getPubPercent();

		const trialText = doctor.trialCount > 0 ? `in the top ${trialPercent}% of led clinical trial specialists` : '';
		const pubText = doctor.publicationCount > 0 ? `in the top ${pubPercent}% of published researchers (who have also led trials)` : '';
		const midText = !!trialText && !!pubText ? 'and' : '';
		const docText = `places ${docName} ${trialText} ${midText} ${pubText} within the diagnosis of Pediatric ${diagnosis.name}`.trim();

		return (
			<Typography className={classes.secondaryContent}>
				This data, combined with the overall expertise of {doctor.hospitalName}, {docText}.
			</Typography>
		);
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle className={classes.dialogTitle}>
				<Grid container direction="row" wrap="nowrap" justifyContent="flex-start" alignItems="center">
					<Grid item >
						<Logo className={classes.svg} />
					</Grid>
					<Grid item>
						<Typography className={classes.title}>{diagnosis.name}</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Grid className={classes.subtitle} container justifyContent="center" alignItems="center">
					<Grid item>
						<Typography className={classes.subtitle}>{docName}, {doctor.hospitalName}</Typography>
					</Grid>
				</Grid>
				<Grid className={classes.content} container direction="column">
					<Grid item>
						<PrimaryContent />
					</Grid>
					<Grid item>
						<SecondaryContent />
					</Grid>
				</Grid>
				<Grid className={classes.linkContent} container justifyContent="center">
					<Link className={classes.link} href={doctor.link} target="_blank" rel="noopener">View External Bio</Link>
				</Grid>
				<Grid container justifyContent="center">
					<Typography className={classes.source}>Source: U.S. National Library of Medicine</Typography>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DoctorModal;