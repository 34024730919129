import { Link, Typography } from "@material-ui/core";
import { ICellRendererParams } from "ag-grid-community";
import { IDoctor } from "../../data/DiagnosisData";
import { useStyles } from './doctorRenderer.styles';

interface IDoctorRendererProps extends ICellRendererParams {
	onClick: (data: IDoctor) => void;
}

const DoctorRenderer = (props: IDoctorRendererProps) => {

	const { data, onClick } = props;
	const classes = useStyles();

	const onDoctorClick = () => {
		onClick(data);
	}

	return (
		<div className={classes.cell}>
			<Typography><Link className={classes.link} onClick={onDoctorClick}>{data.lastName}, {data.firstName}</Link></Typography>
		</div>
	);
};

export const useDoctorRenderer = (onClick: (data: any) => void) => {

	return (params: ICellRendererParams) => {

		const props = {
			onClick,
			...params
		};

		return (
			<DoctorRenderer {...props} />
		);
	}
};

export default DoctorRenderer;