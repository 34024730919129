import { Typography } from "@material-ui/core";
import { ICellRendererParams } from "ag-grid-community";
import { useStyles } from './regionRenderer.styles';

const RegionRenderer = (props: ICellRendererParams) => {

	const { value } = props;
	const classes = useStyles();

	return (
		<div className={classes.cell}>
			<Typography>{value}</Typography>
		</div>
	);
};

export default RegionRenderer;