import { useState, useEffect } from 'react';
import { RouteComponentProps, useParams, navigate, useLocation } from '@reach/router';
import { parse } from "query-string"
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import useWindowOrientation from 'use-window-orientation';
import { useStyles } from "./Report.styles";
import { useDoctorColumns, useHospitalColumns } from './Report.columns';
import { DiagnosisData, DiagnosisDataFactory, IDiagnosis, IDoctor, IHospital } from "../data/DiagnosisData";
import { ChartView, PageView } from '../data/enums';
import { MASTER_CODE } from '../data/enums';
import ViewSwitch from '../common/ViewSwitch.component';
import PageSwitch from '../common/PageSwitch.component';
import { ReactComponent as Logo } from '../common/assets/CureWheel.svg';
import DoctorModal from '../common/DoctorModal.component';
import { getContactReports } from '../data/hubspot';
import ErrorModal from '../common/ErrorModal.component';
import RotateModal from '../common/RotateModal.component';

const LOCAL_STORAGE_KEY = 'SelectedDiagnosisName';
const ORIENTATION_LOCAL_STORAGE_KEY = 'ShowDeviceOrientation';

const dataFactory = new DiagnosisDataFactory();

interface ReportProps extends RouteComponentProps {

}

const Report = (props: ReportProps) => {

	const classes = useStyles();
	const params = useParams();
	const location = useLocation();
	const [rowData, setRowData] = useState<IDoctor[] | IHospital[]>([]);
	const [chartView, setChartView] = useState<ChartView>(ChartView.Doctors);
	const [pageSize, setPageSize] = useState(25);
	const [reports, setReports] = useState<string[]>([]);
	const [diagnosisName, setDiagnosisName] = useState('');
	const [diagnosis, setDiagnosis] = useState<IDiagnosis>();
	const [pageLink, setPageLink] = useState<string>('');
	const [doctorDetail, setDoctorDetail] = useState<IDoctor>();
	const [doctorOpen, setDoctorOpen] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const theme = useTheme();
	const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));
	const { portrait } = useWindowOrientation();
	const [rotateOpen, setRotateOpen] = useState<boolean>(false);


	const showDoctor = (doctor: IDoctor) => {
		setDoctorDetail(doctor);
		setDoctorOpen(true);
	};

	const closeDoctor = () => {
		setDoctorDetail(undefined);
		setDoctorOpen(false);
	};

	const doctorCols = useDoctorColumns(showDoctor);
	const hospitalCols = useHospitalColumns();

	useEffect(() => {

		const reportIdRegExp = new RegExp(/[A-Za-z0-9]{16}/);
		const contactIdRegExp = new RegExp(/[0-9]+/);
		const searchParams = parse(location.search)
		const uniqueId = params.uniqueId ||
			searchParams.reportId ||
			searchParams.contactId ||
			'';

		const isReport = reportIdRegExp.test(uniqueId);
		const isContact = contactIdRegExp.test(uniqueId)

		if (isReport || uniqueId === MASTER_CODE) {

			setPageLink(`/chart?reportId=${uniqueId}`);

			if (uniqueId === MASTER_CODE) {
				setReports(dataFactory.names);

				const selectedDiagnosisName = localStorage.getItem(LOCAL_STORAGE_KEY);

				if (!!selectedDiagnosisName) {
					setDiagnosisName(selectedDiagnosisName);
				}
			}
			else {
				const diagnosisName = dataFactory.getDiagnosisName(uniqueId);

				if (!!diagnosisName) {
					setReports([diagnosisName]);
					setDiagnosisName(diagnosisName);
				}
			}
		}
		else if (isContact) {

			setPageLink(`/chart?contactId=${uniqueId}`);

			getContactReports(uniqueId)
				.then(data => {

					// console.log(`Data:`, data);

					const diagnosisName = data?.properties?.diagnosis;

					if (!!diagnosisName) {
						setReports([diagnosisName]);
						setDiagnosisName(diagnosisName);
					}
					else {
						setError('No diagnosis data was found for the provided contact.');
					}
				})
				.catch(err => {
					setError('No diagnosis data was found for the provided contact.');
				});
		}

	}, [location.search, params.uniqueId]);

	useEffect(() => {

		if (!!diagnosisName) {

			const diagnosis = dataFactory.getDiagnosis(diagnosisName);

			setDiagnosis(diagnosis);

			if (!!diagnosis) {
				const diagnosisData = new DiagnosisData();

				diagnosisData.loadDiagnosis(diagnosis);

				if (!!diagnosisData && !!diagnosisData.name) {

					if (chartView === ChartView.Doctors) {
						setRowData(diagnosisData.doctors);
					}
					else {
						setRowData(diagnosisData.hospitals);
					}
				}
				else {
					setRowData([]);
				}
			}
			else {
				setRowData([]);
			}
		}
		else {
			setRowData([]);
		}

	}, [diagnosisName, chartView]);

	useEffect(() => {
		const orientationSetting = (localStorage.getItem(ORIENTATION_LOCAL_STORAGE_KEY) || 'true') === 'true';

		setRotateOpen(orientationSetting && portrait);

	}, [portrait]);

	const setSelectedDiagnosis = (e: any) => {

		const selectedDiagnosis = e.target.value;

		setDiagnosisName(selectedDiagnosis);

		localStorage.setItem(LOCAL_STORAGE_KEY, selectedDiagnosis);
	};

	const setRotateOpenSetting = (show: boolean) => {
		localStorage.setItem(ORIENTATION_LOCAL_STORAGE_KEY, show ? 'true' : 'false');
	};

	const onGridReady = (params: any) => {

		params.api.sizeColumnsToFit();

		window.addEventListener('resize', function () {
			setTimeout(function () {
				params.api.sizeColumnsToFit();
			});
		});
	};


	return (
		<div className={classes.root}>

			<div className={classes.header}>
				<Grid container wrap="nowrap" alignContent="center">
					<Grid item>
						<Logo className={classes.svg} />
					</Grid>
					<Grid item container alignContent="center">
						<FormControl>
							<Select
								id="diagnosis-name"
								className={classes.title}
								value={diagnosisName}
								onChange={setSelectedDiagnosis}
							>
								{reports.map(report => <MenuItem id={report} value={report}>{report}</MenuItem>)}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container alignItems="center" spacing={1}>
					<Grid item container justifyContent="center" sm={6}>
						<ViewSwitch view={chartView} onClick={() => {
							chartView === ChartView.Doctors ?
								setChartView(ChartView.Hospitals) :
								setChartView(ChartView.Doctors)
						}} />
					</Grid>
					<Grid item container justifyContent={isMobile ? 'center' : 'flex-end'} sm={6}>
						<FormControl className={classes.pageSize}>
							<InputLabel id="page-size-label">Page Size</InputLabel>
							<Select
								labelId="page-size-label"
								id="page-size"
								value={pageSize}
								label="Age"
								onChange={(event: any) => {
									setPageSize(event.target.value);
								}}
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={25}>25</MenuItem>
								<MenuItem value={50}>50</MenuItem>
								<MenuItem value={100}>100</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</div>

			<div className="ag-theme-alpine" style={{ height: isMobile ? '300px' : '600px', minHeight: '300px', width: '100%' }}>
				{chartView === ChartView.Doctors &&
					<AgGridReact
						className={classes.grid}
						headerHeight={50}
						columnDefs={doctorCols}
						rowData={rowData}
						pagination={true}
						paginationPageSize={pageSize}
						suppressMovableColumns={true}
						onGridReady={onGridReady}
					>
					</AgGridReact>
				}
				{chartView === ChartView.Hospitals &&
					<AgGridReact
						className={classes.grid}
						headerHeight={50}
						columnDefs={hospitalCols}
						rowData={rowData}
						pagination={true}
						paginationPageSize={pageSize}
						suppressMovableColumns={true}
						onGridReady={onGridReady}
					>
					</AgGridReact>
				}
			</div>

			<Grid className={classes.source} container>
				<Grid item container justifyContent="center" xs={12}>
					<Typography component="div">
						Source: National Library of Medicine
					</Typography>
				</Grid>
				{pageLink &&
					<Grid item container xs={12} justifyContent="center">
						<PageSwitch view={PageView.Report} onClick={() => navigate(pageLink)} />
					</Grid>
				}
			</Grid>
			{doctorDetail && diagnosis &&
				<DoctorModal open={doctorOpen} doctor={doctorDetail} diagnosis={diagnosis} onClose={closeDoctor} />
			}
			{error &&
				<ErrorModal open={!!error} onClose={() => { setError(''); }} error={error} />
			}
			{isMobile &&
				<RotateModal open={rotateOpen}
					onClose={() => { setRotateOpen(false); }}
					onClosePermanent={() => { setRotateOpen(false); setRotateOpenSetting(false); }}
				/>
			}
		</div>
	);
}

export default Report;