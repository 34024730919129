import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {

		},
		dialogTitle: {
			paddingBottom: '5px'
		},
		dialogContent: {
			paddingTop: '5px'
		},
		title: {
			color: '#2F4857',
			fontSize: 18,
			fontWeight: 'bold'
		},
		subtitle: {
			color: '#5E4119',
			fontSize: 16,
		},
		signup: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		link: {
			padding: '10px',
			textDecoration: 'underline'
		}
	}),
);
