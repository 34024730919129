import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {

		},
		dialogTitle: {
			backgroundColor: theme.palette.primary.main,
			paddingBottom: '5px'
		},
		dialogContent: {
			margin: '0',
			padding: '0'
		},
		title: {
			color: theme.palette.primary.dark,
			fontSize: 26,
			fontWeight: 'bold',
			padding: '10px'
		},
		subtitle: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.secondary.main,
			fontSize: 22,
			fontWeight: 'bold',
			padding: '10px'
		},
		content: {
			padding: '20px'
		},
		primaryContent: {
			fontSize: 20,
			paddingBottom: '10px'
		},
		secondaryContent: {
			fontSize: 16,
			paddingBottom: '10px'
		},
		emphasis: {
			fontWeight: 'bold',
			fontStyle: 'italic'
		},
		svg: {
			maxWidth: 100,
			maxHeight: 100
		},
		linkContent: {
			paddingTop: '10px',
			paddingBottom: '20px'
		},
		link: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.secondary.main,
			fontFamily: theme.typography.fontFamily,
			padding: '10px',
			textDecoration: 'none'
		},
		source: {
			fontFamily: theme.typography.fontFamily,
			fontSize: 14,
		}
	}),
);
