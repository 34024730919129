import { createElement, useState } from "react";
import { Popover, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { ICellRendererParams } from "ag-grid-community";
import { useStyles } from './ratingRenderer.styles';
import { Text } from "victory";

interface RatingRendererProps extends ICellRendererParams {
	hoverText: string[]
}

const RatingRenderer = (props: RatingRendererProps) => {

	const { value, hoverText } = props;
	const classes = useStyles();
	const rating = value * 100 / 20;
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div className={classes.cell} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
			<Rating
				defaultValue={rating}
				precision={0.25} size="small"
				readOnly />
			<Popover
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				{hoverText.map(text => <Typography component="div">{text}</Typography>)}
			</Popover>
		</div>
	);
};

export default RatingRenderer;

export const useDiagnosisRenderer = (props: ICellRendererParams) => {

	const { data } = props;

	return (
		<RatingRenderer {...props} hoverText={[data.diagnosisScore >= 0.6 ? 'Highly Specialized' : 'Specialized']} />
	);
};

export const useOverallRenderer = (props: ICellRendererParams) => {

	const { data } = props;

	return (
		<RatingRenderer {...props} hoverText={[data.overallScore >= 0.6 ? 'Highly Specialized' : 'Specialized']} />
	);
};

export const useTrialsRenderer = (props: ICellRendererParams) => {

	const { data } = props;

	return (
		<RatingRenderer {...props} hoverText={[`Trials Led: ${data.trialCount}`]} />
	);
};

export const usePubsRenderer = (props: ICellRendererParams) => {

	const { data } = props;

	return (
		<RatingRenderer {...props} hoverText={[`Publications & Citations: ${data.publicationCount}`]} />
	);
};