import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {

		},
		switchText: {
			color: '#666666',
			fontSize: '18px'
		},
		switch: {
			color: '#666666',
			switchBase: {
				color: '#666666',
				'&$checked': {
					color: '#666666',
				},
				'&$checked + $track': {
					backgroundColor: '#999999',
				},
			},
			checked: {},
			track: {},
		},
	}),
);