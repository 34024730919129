
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import { useStyles } from './RotateModal.styles';

interface RotateModalProps {
	open: boolean;
	onClose: () => void;
	onClosePermanent: () => void;
}

const RotateModal = (props: RotateModalProps) => {

	const classes = useStyles();
	const { open, onClose, onClosePermanent } = props;

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle className={classes.dialogTitle}>
				<Typography className={classes.title}>Rotate Device</Typography>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<ScreenRotationIcon fontSize="large" />
					</Grid>
					<Grid item>
						<Typography className={classes.subtitle}>Rotate your device horizontally for a better experience.</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Ok
				</Button>
				<Button onClick={onClosePermanent} color="primary">
					Don't Show Again
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default RotateModal;