import { useState, useEffect } from 'react';
import { RouteComponentProps, useParams, navigate, useLocation } from '@reach/router';
import { parse } from "query-string"
import { Grid, Hidden } from '@material-ui/core';
import DiagnosisChart from './components/DiagnosisChart.component';
import DiagnosisList from './components/DiagnosisList.component';
import ChartModal from './components/ChartModal.component';
import DiagnosisModal from './components/DiagnosisModal.component';
import { useStyles } from './Chart.styles';
import { DiagnosisData, DiagnosisDataFactory, IChartPointDatum, IDiagnosis, IDoctor } from '../data/DiagnosisData';
import { MASTER_CODE, PageView } from '../data/enums';
import PageSwitch from '../common/PageSwitch.component';
import DoctorModal from '../common/DoctorModal.component';
import { getContactReports } from '../data/hubspot';
import ErrorModal from '../common/ErrorModal.component';

const dataFactory = new DiagnosisDataFactory();

function Chart(props: RouteComponentProps) {

	const classes = useStyles();
	const params = useParams();
	const location = useLocation();
	const [diagnosisName, setDiagnosisName] = useState('Acute Myeloid Leukemia');
	const [diagnosisData, setDiagnosisData] = useState<DiagnosisData>(new DiagnosisData());
	const [diagnosisLocked, setDiagnosisLocked] = useState<boolean>(false);
	const [detailOpen, setDetailOpen] = useState<boolean>(false);
	const [listOpen, setListOpen] = useState<boolean>(false);
	const [detailDatum, setDetailDatum] = useState<IChartPointDatum | null>(null);
	const [reports, setReports] = useState<string[]>([]);
	const [pageLink, setPageLink] = useState<string>('');
	const [doctorDetail, setDoctorDetail] = useState<IDoctor>();
	const [doctorOpen, setDoctorOpen] = useState<boolean>(false);
	const [doctorDiagnosis, setDoctorDiagnosis] = useState<IDiagnosis>();
	const [error, setError] = useState<string>('');

	const showDoctor = (doctor: IDoctor) => {
		setDoctorDetail(doctor);
		setDoctorOpen(true);
	};

	const closeDoctor = () => {
		setDoctorDetail(undefined);
		setDoctorOpen(false);
	};

	useEffect(() => {

		const reportIdRegExp = new RegExp(/[A-Za-z0-9]{16}/);
		const contactIdRegExp = new RegExp(/[0-9]+/);
		const searchParams = parse(location.search)
		const uniqueId = params.uniqueId ||
			searchParams.reportId ||
			searchParams.contactId ||
			'';

		const isReport = reportIdRegExp.test(uniqueId);
		const isContact = contactIdRegExp.test(uniqueId)

		if (isReport || uniqueId === MASTER_CODE) {

			setPageLink(`/report?reportId=${uniqueId}`);

			if (uniqueId === MASTER_CODE) {
				setReports(dataFactory.names);
			}
			else {
				const diagnosisName = dataFactory.getDiagnosisName(uniqueId);

				if (!!diagnosisName) {
					setReports([diagnosisName]);
					setDiagnosisName(diagnosisName);
				}
			}
		}
		else if (isContact) {

			setPageLink(`/report?contactId=${uniqueId}`);

			getContactReports(uniqueId)
				.then(data => {

					// console.log(`Data:`, data);

					const diagnosisName = data?.properties?.diagnosis;

					if (!!diagnosisName) {
						setReports([diagnosisName]);
						setDiagnosisName(diagnosisName);
					}
					else {
						setError('No diagnosis data was found for the provided contact.');
					}
				})
				.catch(err => {
					setError('No diagnosis data was found for the provided contact.');
				});
		}

	}, [location.search, params.uniqueId]);


	useEffect(() => {
		const diagnosis = dataFactory.getDiagnosis(diagnosisName);
		const diagnosisData = new DiagnosisData();

		diagnosisData.loadDiagnosis(diagnosis);
		setDiagnosisData(diagnosisData);
		setDoctorDiagnosis(diagnosis);

	}, [diagnosisName]);

	const setDiagnosis = (name: string) => {

		if (!diagnosisLocked) {
			setDiagnosisName(name);
		}
	};

	const toggleDiagnosisLock = () => {
		setDiagnosisLocked(!diagnosisLocked);
	};

	const showDetail = (datum: IChartPointDatum) => {
		setDetailDatum(datum);
		setDetailOpen(true);
	};

	const closeDetail = () => {
		setDetailDatum(null);
		setDetailOpen(false);
	};

	const showList = () => {
		setListOpen(true);
	};

	const closeList = () => {
		setListOpen(false);
	};


	return (
		<div className={classes.root}>
			<Grid container spacing={0}>
				<Grid item xs={12} sm={7}>
					<DiagnosisChart diagnosisData={diagnosisData} showDetail={showDetail} showList={showList} />
				</Grid>
				<Hidden smDown>
					<Grid className={classes.sideList} item alignItems="flex-start" xs={12} sm={5}>
						<DiagnosisList dataFactory={dataFactory} setDiagnosis={setDiagnosis} toggleDiagnosisLock={toggleDiagnosisLock} />
					</Grid>
				</Hidden>
				{pageLink &&
					<Grid item container xs={12} justifyContent="center">
						<PageSwitch view={PageView.Chart} onClick={() => navigate(pageLink)} />
					</Grid>
				}
			</Grid>
			<Hidden mdUp>
				<DiagnosisModal open={listOpen} onClose={closeList} dataFactory={dataFactory} setDiagnosis={setDiagnosis} toggleDiagnosisLock={toggleDiagnosisLock} />
			</Hidden>
			<ChartModal open={detailOpen} datum={detailDatum} onClose={closeDetail} onClick={showDoctor} reports={reports} />
			{doctorDetail && doctorDiagnosis &&
				<DoctorModal open={doctorOpen} doctor={doctorDetail} diagnosis={doctorDiagnosis} onClose={closeDoctor} />
			}
			{error &&
				<ErrorModal open={!!error} onClose={() => { setError(''); }} error={error} />
			}
		</div>
	);
}

export default Chart;
