import allData from './Acute Lymphoblastic Leukemia.json';
import amlData from './Acute Myeloid Leukemia.json';
import acData from './Astrocytoma.json';
import blData from './Burkitt Lymphoma.json';
import csData from './Chondrosarcoma.json';
import ccsData from './Clear Cell Sarcoma.json';
import esData from './Embryonal Sarcoma.json';
import epData from './Ependymoma.json';
import ewsData from './Ewing Sarcoma.json';
import fsData from './Fibrosarcoma.json';
import gbData from './Ganglioneuroblastoma.json';
import gctbData from './Germ Cell Tumor Of Childhood Brain.json';
import gctcData from './Germ Cell Tumor of CNS.json';
import gcttData from './Germ Cell Tumor Of Testis.json';
import gData from './Glioma.json';
import gcData from './Gonadal Carcinomas.json';
import hbData from './Hepatoblastoma.json';
import hcData from './Hepatic Carcinoma.json';
import hdData from './Hodgkin Disease.json';
import mmData from './Malignant Melanoma.json';
import ncData from './Nasopharyngeal Carcinoma.json';
import nbData from './Neuroblastoma.json';
import nfData from './Neurofibrosarcoma.json';
import nhlData from './Non Hodgkin Lymphoma.json';
import osData from './Osteosarcoma.json';
import pntData from './Primitive Neuroectodermal Tumors.json';
import rcData from './Renal Carcinoma.json';
import rbData from './Retinoblastoma.json';
import rtData from './Rhabdoid Tumor.json';
import rsData from './Rhabdomyosarcoma.json';
import stData from './Soft Tissue Sarcoma.json';
import ssData from './Synovial Sarcoma.json';
import tcData from './Thyroid Carcinoma.json';
import wtData from './Wilms Tumor.json';

const rawData = [
	allData,
	amlData,
	acData,
	blData,
	csData,
	ccsData,
	esData,
	epData,
	ewsData,
	fsData,
	gbData,
	gcData,
	gctbData,
	gctcData,
	gcttData,
	gData,
	hbData,
	hcData,
	hdData,
	mmData,
	ncData,
	nbData,
	nfData,
	nhlData,
	osData,
	pntData,
	rcData,
	rbData,
	rtData,
	rsData,
	stData,
	ssData,
	tcData,
	wtData
];

export default rawData;