export const MASTER_CODE = '8675309-Jenny81';

export enum PageView {
	Chart,
	Report
}

export enum ChartView {
	Doctors,
	Hospitals
}