import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: '10px'
		},
		cell: {
			display: 'flex',
			flexDirection: 'column',
			paddingBottom: '10px'
		},
		link: {
			fontFamily: theme.typography.fontFamily,
			fontSize: '1.1em',
			textDecoration: 'underline',
			cursor: 'pointer'
		},
		linkIcon: {
			fontSize: '0.9em'
		},
		popover: {
			pointerEvents: 'none',
		},
		paper: {
			padding: theme.spacing(1),
		},
	}),
);