import { Grid, Switch, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useStyles } from "./PageSwitch.styles";
import { PageView } from '../data/enums';

interface PageSwitchProps {
	view: PageView;
	onClick: () => void;
}

const GreySwitch = withStyles({
	switchBase: {
		color: grey[300],
		'&$checked': {
			color: grey[300],
		},
		'&$checked + $track': {
			backgroundColor: grey[800],
		},
	},
	checked: {},
	track: {},
})(Switch);

const PageSwitch = (props: PageSwitchProps) => {

	const classes = useStyles();
	const { view, onClick } = props;

	return (
		<Typography className={classes.switchText} component="div">
			<Grid component="label" container alignItems="center" spacing={1}>
				<Grid item>
					<Typography component="span" style={{ color: view === PageView.Chart ? '#FFB03B' : '#666666' }}>Chart</Typography>
				</Grid>
				<Grid item>
					<GreySwitch checked={view !== PageView.Chart} onChange={onClick} />
				</Grid>
				<Grid item>
					<Typography component="span" style={{ color: view === PageView.Report ? '#FFB03B' : '#666666' }}>Report</Typography>
				</Grid>
			</Grid>
		</Typography>
	);
}

export default PageSwitch;
