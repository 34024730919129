// import ShortUniqueId from 'short-unique-id';
// import * as _ from 'lodash';
import { ChartView } from './enums';
import rawData from './raw';

// const uid = new ShortUniqueId({ length: 16 });

// for (const x of _.range(2)) {
// 	console.log(uid());
// }

const diagnosisIdMap: { [key: string]: string } = {
	'4Kfk8bKdzp8zuEAB': 'Acute Lymphoblastic Leukemia',
	'KJ87uIpYO0OYWPi9': 'Acute Myeloid Leukemia',
	'DwuxlJCwSrtN94Xk': 'Astrocytoma',
	'qRtUB78ERItVKJc6': 'Burkitt Lymphoma',
	'R7qtC5F1WiVIczeQ': 'Chondrosarcoma',
	'AXT1oEXlfGTbTQgU': 'Clear Cell Sarcoma',
	'sfd8WUo1xeZXjbos': 'Embryonal Sarcoma',
	'f8GWpHWCCwws5jka': 'Ependymoma',
	'2UfDU7WSwFvn5V9x': 'Ewing Sarcoma',
	'LvaHLV5JiveUMpLU': 'Fibrosarcoma',
	'62y4mjtwnBr6A8P8': 'Ganglioneuroblastoma',
	'ulcojD5S9Igk5h5q': 'Germ Cell Tumor Of Childhood Brain',
	'bIPeuzVtLz4Yu3q1': 'Germ Cell Tumor Of Testis',
	'lp7SMJbeKYxckN4n': 'Germ Cell Tumor of CNS',
	'jwopQGhAHbekIpCy': 'Glioma',
	'iM2hTjr1OPSrYcLL': 'Gonadal Carcinomas',
	'YqsPTpw4Fkv8S8wP': 'Hepatoblastoma',
	'j5ICxhMaZrVgayTV': 'Hepatic Carcinoma',
	'VNxFrzVEqLUDwoYs': 'Hodgkin Disease',
	'rSamuZptvtQUd71C': 'Malignant Melanoma',
	'eCR9agvCY8jAFf4P': 'Nasopharyngeal Carcinoma',
	'hqlsTw4KlKxggZBt': 'Neuroblastoma',
	'eBP78vuP7jZ4rPU5': 'Neurofibrosarcoma',
	'N1rC13rFR9WhZimr': 'Non Hodgkin Lymphoma',
	'Raono7CDuJqzCSeg': 'Osteosarcoma',
	'P4qxjh5kxd96e3Eo': 'Primitive Neuroectodermal Tumors',
	'GvCtUZrWvPdeTcc6': 'Renal Carcinoma',
	'x6M77jJLsvDKu1b3': 'Retinoblastoma',
	'ZWnDyxbEuUHNzDnc': 'Rhabdoid Tumor',
	'Y8ZuycLHUlD77iro': 'Rhabdomyosarcoma',
	'Fm5iia3rL5hOazrM': 'Soft Tissue Sarcoma',
	'0REOslm3XPWLZu5h': 'Synovial Sarcoma',
	'okVWSsPKQRM6eB6T': 'Thyroid Carcinoma',
	'H6CwScZ8YuIrUCKH': 'Wilms Tumor'
};
export interface IScoreData {
	city: string;
	state: string;
	region: string;
	link: string;
	diagnosisScore: number;
	overallScore: number;
	trialScore: number;
	trialCount: number;
	publicationScore: number;
	publicationCount: number;
}

export interface IDoctor extends IScoreData {
	firstName: string;
	lastName: string;
	hospitalName: string;
}

export interface IHospital extends IScoreData {
	name: string;
}

export interface IDiagnosis {
	name: string;
	doctors: IDoctor[];
	hospitals: IHospital[];
}

export interface IChartPointDatum {
	x: number,
	y: number,
	chartView: ChartView,
	diagnosis: DiagnosisData,
	mapKey: string,
	uniqueIndex: number,
	item: IScoreData
}

export class DiagnosisDataFactory {

	private diagnoses: { [key: string]: IDiagnosis };
	private diagnosisNames: string[] = [];

	constructor() {

		this.diagnoses = {};

		const toFloat = (value: number) => {
			return parseFloat(value.toPrecision(2));
		};

		rawData.forEach(item => {

			const doctors: IDoctor[] = [];
			const hospitals: IHospital[] = [];
			const hospitalDict: { [key: string]: boolean } = {};

			item.doctor_scores.forEach(doctor => {

				doctors.push({
					firstName: doctor.doctorfirst,
					lastName: doctor.doctorlast,
					hospitalName: doctor.hosp_name,
					city: doctor.hosp_city || '',
					state: doctor.hosp_state || '',
					region: doctor.region || '',
					link: doctor.doc_link || '',
					diagnosisScore: toFloat(doctor.cumscorekpi1 || 0),
					overallScore: 0,
					trialScore: toFloat(doctor.ct_doc_kpi2 || 0),
					trialCount: toFloat(doctor.ct_raw_doc || 0),
					publicationScore: toFloat(doctor.pub_doc_kpi4 || 0),
					publicationCount: toFloat(doctor.pub_raw_doc || 0)
				});

				if (!hospitalDict[doctor.hosp_name]) {

					hospitals.push({
						name: doctor.hosp_name,
						city: doctor.hosp_city || '',
						state: doctor.hosp_state || '',
						region: doctor.region || '',
						link: doctor.hosp_link || '',
						diagnosisScore: toFloat(doctor.cumhosp_diagnosis_kpi7 || 0),
						overallScore: toFloat(doctor.overall_hosp_kpi6 || 0),
						trialScore: toFloat(doctor.ct_hosp_kpi3 || 0),
						trialCount: toFloat(doctor.ct_raw_hosp || 0),
						publicationScore: toFloat(doctor.pub_hosp_kpi5 || 0),
						publicationCount: toFloat(doctor.pub_raw_hosp || 0)
					});

					hospitalDict[doctor.hosp_name] = true;
				}
			});

			this.diagnoses[item.diagnosis] = {
				name: item.diagnosis,
				doctors: doctors.sort((a, b) => {
					return b.diagnosisScore - a.diagnosisScore;
				}),
				hospitals: hospitals.sort((a, b) => {
					return b.diagnosisScore - a.diagnosisScore;
				})
			};

			this.diagnosisNames.push(item.diagnosis);
		});
	}

	get names(): string[] {
		return [
			...this.diagnosisNames.sort()
		];
	}

	isLoaded(name: string): boolean {
		return !!this.diagnoses[name];
	}

	getDiagnosis(name: string): IDiagnosis {
		return this.diagnoses[name];
	}

	getDiagnosisName(id: string): string {
		return diagnosisIdMap[id];
	}
}

export class DiagnosisData {

	private diagnosis: IDiagnosis | null = null;
	public doctorPointMap: { [key: string]: IDoctor[] } = {};
	public hospitalPointMap: { [key: string]: IHospital[] } = {};

	get name() {
		return this.diagnosis ? this.diagnosis.name : '';
	}

	get doctors() {
		return this.diagnosis ? this.diagnosis.doctors : [];
	}

	get hospitals() {
		return this.diagnosis ? this.diagnosis.hospitals : [];
	}

	get uniqueDoctorPoints(): IDoctor[] {

		const points: IDoctor[] = [];

		Object.values(this.doctorPointMap)
			.forEach(point => points.push(point[0]));

		return points;
	}

	get uniqueHospitalPoints(): IHospital[] {

		const points: IHospital[] = [];

		Object.values(this.hospitalPointMap)
			.forEach(point => points.push(point[0]));

		return points;
	}

	loadDiagnosis(diagnosis: IDiagnosis): void {

		this.diagnosis = diagnosis

		this.doctorPointMap = {};
		this.hospitalPointMap = {};

		this.diagnosis.doctors
			.forEach(item => {

				const key = `${item.diagnosisScore}:${item.trialScore}:${item.publicationScore}`;

				if (!this.doctorPointMap[key]) {
					this.doctorPointMap[key] = [];
				}

				this.doctorPointMap[key].push(item);
			});

		this.diagnosis.hospitals
			.forEach(item => {

				const key = `${item.diagnosisScore}:${item.trialScore}:${item.publicationScore}`;

				if (!this.hospitalPointMap[key]) {
					this.hospitalPointMap[key] = [];
				}

				this.hospitalPointMap[key].push(item);
			});
	}
}
