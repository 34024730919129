import { useState } from 'react';
import { VictoryLine, VictoryScatter, VictoryTheme, VictoryTooltip, VictoryLabel } from 'victory';
import { Button, Grid, IconButton, Switch, Typography, useMediaQuery, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from '@material-ui/core/styles';
import { DiagnosisData, IChartPointDatum, IScoreData } from '../../data/DiagnosisData';
import ChartPoint from './ChartPoint.component';
import { useStyles } from './DiagnosisChart.styles';
import { ChartView } from '../../data/enums';
import { grey } from '@material-ui/core/colors';

interface DiagnosisChartProps {
	diagnosisData: DiagnosisData;
	showDetail: (datum: IChartPointDatum) => void;
	showList: () => void;
}

interface ChartButtonProps {
	x: number,
	y: number,
	color: 'primary' | 'secondary' | 'default' | 'inherit' | undefined,
	text: string,
	onClick: () => void
}

interface ChartSwitchProps {
	x: number,
	y: number,
	onClick: () => void
}

const DiagnosisChart = (props: DiagnosisChartProps) => {

	const classes = useStyles();
	const { diagnosisData, showDetail, showList } = props;
	const [chartView, setChartView] = useState<ChartView>(ChartView.Doctors);
	const theme = useTheme();
	const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

	const xMin = 0;
	const xMax = 120;
	const yMin = 0;
	const yMax = 120;

	const slope = (yMax - yMin) / (xMax - xMin);
	const angle = Math.atan(slope);
	const unit = 15;

	const ChartIconButton = (props: ChartButtonProps) => {

		const classes = useStyles();
		const { x, y, onClick } = props;

		return (
			<foreignObject x={x} y={y} width="50" height="50" >
				<IconButton className={classes.iconButton} size="small" onClick={onClick}><MenuIcon /></IconButton>
			</foreignObject>
		);
	};

	const GreySwitch = withStyles({
		switchBase: {
			color: grey[300],
			'&$checked': {
				color: grey[300],
			},
			'&$checked + $track': {
				backgroundColor: grey[800],
			},
		},
		checked: {},
		track: {},
	})(Switch);

	const ChartViewSwitch = (props: ChartSwitchProps) => {

		const { x, y, onClick } = props;

		return (
			<>
				{isMobile &&
					<foreignObject x={x - 50} y={y} width="180" height="75" >
						<Typography className={classes.switchTextMobile} component="div">
							<Grid component="label" container alignItems="center" wrap="nowrap" spacing={0}>
								<Grid item>Doctors</Grid>
								<Grid item>
									<GreySwitch checked={chartView !== ChartView.Doctors} onChange={onClick} />
								</Grid>
								<Grid item>Hospitals</Grid>
							</Grid>
						</Typography>
					</foreignObject>
				}
				{!isMobile &&
					<foreignObject x={x} y={y} width="230" height="75" >
						<Typography className={classes.switchText} component="div">
							<Grid component="label" container alignItems="center" wrap="nowrap" spacing={1}>
								<Grid item>Doctors</Grid>
								<Grid item>
									<GreySwitch checked={chartView !== ChartView.Doctors} onChange={onClick} />
								</Grid>
								<Grid item>Hospitals</Grid>
							</Grid>
						</Typography>
					</foreignObject>
				}
			</>
		);
	}

	const getTrialCoordinates = (item: IScoreData, x: number, y: number): { x: number, y: number } => {

		const xx = item.trialScore * unit * Math.cos(angle);
		const yy = item.trialScore * unit * Math.sin(angle);

		return {
			x: x - xx,
			y: y + yy
		}
	};

	const getPublicationCoordinates = (item: IScoreData, x: number, y: number): { x: number, y: number } => {

		const xx = item.publicationScore * unit * Math.cos(angle);
		const yy = item.publicationScore * unit * Math.sin(angle);

		return {
			x: x + xx,
			y: y - yy
		}
	};

	const getItemCoordinates = (item: IScoreData): { x: number, y: number } => {

		const x = item.diagnosisScore * 100;
		const y = x * slope;

		const coords = item.trialScore === item.publicationScore ?
			{ x, y } :
			item.trialScore > item.publicationScore ?
				getTrialCoordinates(item, x, y) :
				getPublicationCoordinates(item, x, x);

		return {
			x: coords.x < 1 ? coords.x + 5 : coords.x,
			y: coords.y < 1 ? coords.y + 5 : coords.y
		};
	};

	const getTooltipOrientation = (datum: any): 'top' | 'bottom' | 'left' | 'right' => {

		const item = (datum.datum as IChartPointDatum).item;

		if (item.diagnosisScore * 100 > 90) {
			return 'bottom';
		}
		else {
			return 'top';
		}
	};

	const series = chartView === ChartView.Doctors ?
		diagnosisData.uniqueDoctorPoints
			.map((item, index) => {
				return {
					...getItemCoordinates(item),
					chartView,
					diagnosis: diagnosisData,
					mapKey: `${item.diagnosisScore}:${item.trialScore}:${item.publicationScore}`,
					uniqueIndex: index,
					item
				};
			}) :
		diagnosisData.uniqueHospitalPoints
			.map((item, index) => {
				return {
					...getItemCoordinates(item),
					chartView,
					diagnosis: diagnosisData,
					mapKey: `${item.diagnosisScore}:${item.trialScore}:${item.publicationScore}`,
					uniqueIndex: index,
					item
				};
			});

	const chartTitle = `${chartView === ChartView.Doctors ? 'Doctors' : 'Hospitals'}: ${diagnosisData.name}`;

	return (
		<div className={classes.root}>
			<svg viewBox='0 0 600 400'>

				<g>
					<polygon points='0,0 600,0 0,400' fill='#DCDCDC' />
					<polygon points='600,0 600,400 0,400' fill='#8E9AA0' />

					{isMobile &&
						<ChartIconButton x={10} y={2} text="" color="default" onClick={showList} />
					}

					<VictoryLabel x={10} y={75} text={chartTitle} style={{
						fontFamily: 'Poppins',
						fontSize: '20px',
					}} />

					<ChartViewSwitch x={200} y={5} onClick={() => {
						chartView === ChartView.Doctors ?
							setChartView(ChartView.Hospitals) :
							setChartView(ChartView.Doctors)
					}} />

					<VictoryLabel x={40} y={130} text="Clinical Trials" style={{
						fontFamily: 'Poppins',
						fontSize: '30px',
						fill: 'none',
						stroke: '#999999',
						strokeWidth: '1px'
					}} />
					<VictoryLabel x={360} y={320} text="Publications &amp;" style={{
						fontFamily: 'Poppins',
						fontSize: '30px',
						fill: 'none',
						stroke: '#FFFFFF',
						strokeWidth: '1px'
					}} />
					<VictoryLabel x={400} y={350} text="Citations" style={{
						fontFamily: 'Poppins',
						fontSize: '30px',
						fill: 'none',
						stroke: '#FFFFFF',
						strokeWidth: '1px'
					}} />

					<VictoryLine
						domain={{ x: [0, 120], y: [0, 120] }}
						style={{
							data: { stroke: '#344856', strokeDasharray: '5,5' },
						}}
						data={[
							{ x: 0, y: 0 },
							{ x: 120, y: 120 }
						]}
						standalone={false}
						padding={0}
						height={400}
						width={600}
					/>
					<polygon points='580,3 600,0 590,18' fill='#344856' />

					<VictoryLabel x={310} y={220} text="EXPERTISE" style={{
						fontFamily: 'Poppins',
						fontSize: '20px',
						fill: '#CDCDCD',
						transform: 'rotate(-34, 310, 220)'
					}} />
					<VictoryLabel x={30} y={360} text="LESS" style={{
						fontFamily: 'Poppins',
						fontSize: '18px',
						fill: '#344856',
						transform: 'rotate(-34, 30, 360)'
					}} />
					<VictoryLabel x={510} y={40} text="MORE" style={{
						fontFamily: 'Poppins',
						fontSize: '18px',
						fill: '#344856',
						transform: 'rotate(-34, 510, 40)'
					}} />

					<VictoryScatter
						data={series}
						domain={{ x: [0, 120], y: [0, 120] }}
						labels={(datum) => {

							const item = (datum.datum as IChartPointDatum).item;

							return `Trials: ${item.trialCount}\nPublications & Citations: ${item.publicationCount}\n(click for list)`;
						}}
						// labelComponent={<VictoryTooltip flyoutComponent={<PointPopover />} />}
						labelComponent={<VictoryTooltip orientation={(datum) => getTooltipOrientation(datum)} />}
						dataComponent={<ChartPoint />}
						theme={VictoryTheme.material}
						standalone={false}
						events={[
							{
								target: 'data',
								eventHandlers: {
									onClick: (event, item) => {
										// console.log(item);
										showDetail(item.datum);
									}
								}
							}
						]}
						padding={0}
						height={400}
						width={600}
					/>

					<VictoryLabel x={400} y={390} text="Source: National Library of Medicine" style={{
						fontFamily: 'Poppins',
						fontSize: '10px',
					}} />

				</g>
			</svg>
		</div>
	);
};

export default DiagnosisChart;