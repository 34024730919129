import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderTop: '30px solid #FFB03B',
			padding: '10px'
		},
		header: {
			position: 'sticky',
			top: 30
		},
		title: {
			fontSize: '1.3em',
			padding: '10px'
		},
		subtitle: {
			fontSize: '1.1em',
			padding: '5px'
		},
		grid: {
			fontFamily: 'Poppins',
			'& .ag-header-cell': {
				padding: '3px'
			},
			'& .ag-header-cell-label .ag-header-cell-text': {
				textAlign: 'center',
				whiteSpace: 'normal !important'
			},
			'& div.ag-header-cell[col-id="region"] div.ag-header-cell-label': {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center'
			},
			'& div.ag-header-cell[col-id="trialCount"] div.ag-header-cell-label': {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center'
			},
			'& div.ag-header-cell[col-id="publicationCount"] div.ag-header-cell-label': {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center'
			}
		},
		centerCell: {
			'& .ag-cell-wrapper': {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center'
			}
		},
		switchText: {
			color: '#666666',
			fontSize: '18px'
		},
		switch: {
			color: '#666666',
			switchBase: {
				color: '#666666',
				'&$checked': {
					color: '#666666',
				},
				'&$checked + $track': {
					backgroundColor: '#999999',
				},
			},
			checked: {},
			track: {},
		},
		pageSize: {
			minWidth: '125px'
		},
		svg: {
			maxWidth: 100,
			maxHeight: 100
		},
		source: {
			paddingTop: '10px'
		}
	}),
);