import { Button, TextField, useMediaQuery } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { useState } from 'react';
import { DiagnosisDataFactory } from "../../data/DiagnosisData";
import { useStyles } from './DiagnosisList.styles';

interface DiagnosisListProps {
	dataFactory: DiagnosisDataFactory,
	setDiagnosis: (name: string) => void,
	toggleDiagnosisLock: () => void
}

const DiagnosisList = (props: DiagnosisListProps) => {

	const { dataFactory, setDiagnosis, toggleDiagnosisLock } = props;
	const classes = useStyles();
	const [lockedName, setLockedName] = useState<string>('');
	const [filter, setFilter] = useState('');
	const theme = useTheme();
	const isModal = useMediaQuery(() => theme.breakpoints.down('sm'));


	const toggleLock = (name: string) => {

		if (lockedName) {
			setLockedName('');
		}
		else {
			setLockedName(name);
		}

		toggleDiagnosisLock();
	};

	let key = 1;

	return (
		<div className={isModal ? classes.root : `${classes.root} ${classes.modal}`}>
			<TextField className={classes.search} label="Diagnosis Search" onChange={event => setFilter(event.target.value)} />
			{dataFactory.names
				.filter(name => filter.length < 2 || name.toLowerCase().includes(filter.toLowerCase()))
				.map(name =>
					<Button
						key={key++}
						variant="outlined"
						className={classes.diagnosisBtn}
						onMouseOver={() => {
							if (isModal) {
								return;
							}
							else {
								setDiagnosis(name);
							}
						}}
						onClick={() => {
							if (isModal) {
								setDiagnosis(name);
							}
							else {
								toggleLock(name);
							}
						}}
						startIcon={
							isModal ? <></> : name === lockedName ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />
						}
						disabled={!dataFactory.isLoaded(name) || (!!lockedName && name !== lockedName)}
						data-active={name === lockedName}
					>{name}</Button>)
			}
		</div>
	);
}

export default DiagnosisList;