import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@material-ui/core';
import { IChartPointDatum, IDoctor } from '../../data/DiagnosisData';

import { useStyles } from './ChartModal.styles';
import { ChartView } from '../../data/enums';

interface ChartModalProps {
	open: boolean;
	onClose: () => void;
	onClick: (doctor: IDoctor) => void;
	datum: IChartPointDatum | null;
	reports: string[]
}

const ChartModal = (props: ChartModalProps) => {

	const classes = useStyles();
	const { open, onClose, onClick, datum, reports } = props;
	const doctors = datum ? datum.diagnosis.doctorPointMap[datum.mapKey] : [];
	const hospitals = datum ? datum.diagnosis.hospitalPointMap[datum.mapKey] : [];
	const showData = !!datum && reports.length > 0 && reports.includes(datum.diagnosis.name);
	let key = 1;

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle className={classes.dialogTitle}>
				{datum?.diagnosis.name} {datum?.chartView === ChartView.Doctors ? 'Doctors' : 'Hospitals'}
				<Typography className={classes.title}>{`Trials: ${datum?.item.trialCount}, Publications & Citations: ${datum?.item.publicationCount}`}</Typography>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				{showData && datum?.chartView === ChartView.Doctors &&
					<>
						{doctors.map(doctor =>
							<div key={key++}>
								<Typography className={classes.title}>
									<Link className={classes.link} onClick={() => { onClick(doctor); }}>{doctor.firstName} {doctor.lastName}</Link>
								</Typography>
								<Typography className={classes.subtitle} color="textSecondary">
									{doctor.hospitalName}
								</Typography>
								<Typography className={classes.subtitle} color="textSecondary">
									{doctor.city}, {doctor.state}
								</Typography>
							</div>
						)}
					</>
				}
				{showData && datum?.chartView === ChartView.Hospitals &&
					<>
						{hospitals.map(hospital =>
							<div key={key++}>
								<Typography className={classes.title}>
									{hospital.name}
								</Typography>
								<Typography className={classes.subtitle} color="textSecondary" gutterBottom>
									{hospital.city}, {hospital.state}
								</Typography>
							</div>
						)}
						<Typography className={classes.subtitle}>Trials: {hospitals[0].trialCount}</Typography>
						<Typography className={classes.subtitle}>Publications: {hospitals[0].publicationCount}</Typography>
					</>
				}
				{!showData &&
					<div className={classes.signup}>
						<Typography component="div">
							<Button variant="contained" color="primary" onClick={() => window.open('/find-a-specialist', '_blank')}>
								Unlock Data
							</Button>
						</Typography>
						<Typography component="div" style={{ paddingTop: '10px', margin: 'auto', width: '90%' }}>
							to view detailed information on {datum?.chartView === ChartView.Doctors ? `${datum?.diagnosis.doctors.length} doctors` : `${datum?.diagnosis.hospitals.length} hospitals`} that specialize in Pediatric {datum?.diagnosis.name}
						</Typography>
					</div>
				}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ChartModal;