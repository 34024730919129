import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { DiagnosisDataFactory } from "../../data/DiagnosisData";
import { useStyles } from './ChartModal.styles';
import DiagnosisList from "./DiagnosisList.component";

interface DiagnosisModalProps {
	open: boolean;
	onClose: () => void;
	dataFactory: DiagnosisDataFactory,
	setDiagnosis: (name: string) => void,
	toggleDiagnosisLock: () => void
}

const DiagnosisModal = (props: DiagnosisModalProps) => {

	const classes = useStyles();
	const { open, onClose, dataFactory, setDiagnosis, toggleDiagnosisLock } = props;

	return (
		<Dialog className={classes.root} open={open} scroll="paper">
			<DialogContent className={classes.dialogContent}>
				<DiagnosisList dataFactory={dataFactory} setDiagnosis={(name) => { setDiagnosis(name); onClose(); }} toggleDiagnosisLock={toggleDiagnosisLock} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DiagnosisModal;