import React from 'react';
import { Point, PointProps } from 'victory';
import { IChartPointDatum } from '../../data/DiagnosisData';


const ChartPoint = (props: PointProps) => {

	// console.log(props);
	const datum = props.datum as IChartPointDatum;

	const scale = Math.floor(((Math.floor(datum.item.diagnosisScore * 100) * 4) / 100)) + 1;
	//const scale = Math.floor(datum.item.diagnosisScore * 100 / 5);

	// console.log(`score: ${datum.item.diagnosisScore}, scale: ${scale}`);

	const colors = [
		'#FFDAA7',
		'#FFD08C',
		'#FFC571',
		'#FFBA56',
		'#FFB03B',
	];

	// console.log(`x:${x}, y:${y}, xMax:${xMax}, xMin:${xMin}, yMax:${yMax}, yMin:${yMin}, slope:${slope}, lineY:${lineY}`);

	return (
		<Point {...props} size={10} style={{ opacity: scale / 3, fill: '#FFB03B', filter: 'drop-shadow(-2px 1px 2px rgba(0, 0, 0, 0.3))' }} />
	);
}

export default ChartPoint;