import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Opacity } from '@material-ui/icons';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			border: 'solid 3px #2F4857',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			padding: 0,
			margin: 0,
			'& svg': {
				display: 'block'
			}
		},
		button: {
			textTransform: 'none',
			fontSize: 16,
			width: '100%',
		},
		iconButton: {
			color: '#344856'
		},
		fab: {
			height: '30px',
			width: '30px',
			opacity: 1
		},
		icon: {
			filter: 'drop-shadow(-2px 2px 1px rgba(9, 9, 9, .4))'
		},
		switchText: {
			color: '#666666',
			fontSize: '18px',
			position: 'fixed'
		},
		switchTextMobile: {
			color: '#666666',
			fontSize: '12px',
			position: 'fixed'
		},
		switch: {
			color: '#666666',
			switchBase: {
				color: '#666666',
				'&$checked': {
					color: '#666666',
				},
				'&$checked + $track': {
					backgroundColor: '#999999',
				},
			},
			checked: {},
			track: {},
		}
	}),
);

