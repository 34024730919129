import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: '10px'
		},
		cell: {
			display: 'flex',
			flexDirection: 'column',
			paddingBottom: '10px'
		},
	}),
);