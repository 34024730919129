
const urlBase = 'https://us-central1-curewheel.cloudfunctions.net/app/hubspot';

export const getContactReports = (contactId: string): Promise<any> => {

	return new Promise((resolve, reject) => {
		fetch(`${urlBase}/contact/${contactId}`)
			.then(response => response.json())
			.then(json => resolve(json))
			.catch(err => reject(err));
	});

}