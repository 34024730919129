import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: '10px'
		},
		chart: {
			width: '800px'
		},
		buttons: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-evenly',
			padding: '10px 0px'
		},
		button: {
			fontSize: 24,
			minWidth: '200px',
			textTransform: 'none'
		},
		title: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			color: 'black',
			fontSize: 20,
			fontWeight: 'bold',
			paddingTop: 20
		},
		names: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap'
		},
		listName: {
			fontSize: 10,
			marginRight: '5px'
		},
		sideList: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			maxHeight: '590px'
		},

	}),
);