
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useStyles } from './ErrorModal.styles';

interface ErrorModalProps {
	open: boolean;
	onClose: () => void;
	error: string
}

const ErrorModal = (props: ErrorModalProps) => {

	const classes = useStyles();
	const { open, onClose, error } = props;

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle className={classes.dialogTitle}>
				<Typography className={classes.title}>Oops!</Typography>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Typography className={classes.subtitle}>{error}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ErrorModal;