import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "@reach/router"
import { ThemeProvider } from '@material-ui/core/styles';
import 'typeface-poppins';
import 'typeface-barlow-condensed';
import Chart from './chart/Chart.component';
import Report from './report/Report.component';
import theme from './App.theme';


ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Router>
				<Chart path="/" />
				<Chart path="chart" />
				<Chart path="chart/:uniqueId" />
				<Report path="report" />
				<Report path="report/:uniqueId" />
			</Router>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('curewheel_chart')
);


