import { useState } from "react";
import { Grid, Link, Popover, Typography, useMediaQuery } from "@material-ui/core";
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import { ICellRendererParams } from "ag-grid-community";
import { useStyles } from './hospitalRenderer.styles';
import theme from "../../App.theme";

const HospitalRenderer = (props: ICellRendererParams) => {

	const { value, data } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div className={classes.cell} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
			<Typography><Link className={classes.link} href={data.link} target="_blank" rel="noopener">{value}</Link></Typography>
			<Popover
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Grid container justifyContent="center">
					<Grid item xs={12}>
						<Typography><strong>City:</strong> {data.city}, {data.state}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography><strong>Region:</strong> {data.region}</Typography>
					</Grid>
				</Grid>

			</Popover>
		</div>
	);
};

export default HospitalRenderer;