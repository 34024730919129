import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexWrap: 'nowrap'
		},
		header: {
			fontSize: '1em',
			fontWeight: 'bold',
			whiteSpace: 'normal'
		}
	}),
);
