import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#4F9C58',
			light: '#A2AC39',
			dark: '#005248',
			contrastText: '#E0E0E0'
		},
		secondary: {
			main: '#FFB03B',
			contrastText: '#2F4857'
		}
	},
	typography: {
		fontFamily: [
			'Poppins',
			'Barlow Condensed',
			'sans serif',
		].join(','),
	}
	// colors: {
	// 	yellow: '#FFB03B',
	// 	green: '#4F9C58',
	// 	lightGreen: '#A2AC39',
	// 	darkGreen: '#005248',
	// 	brown: '#5E4119',
	// 	black: '#2F4857',
	// 	grey: '#E0E0E0'
	// }
});

export default theme;