import { useEffect, useState } from 'react';
import { IHeaderParams } from 'ag-grid-community';
import { Grid, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles } from './columnHeader.styles';
import { IconButton } from '@material-ui/core';

const ColumnHeader = (props: IHeaderParams) => {

	const classes = useStyles();
	const [sortAsc, setSortAsc] = useState(props.column.isSortAscending() ? 'active' : 'inactive');
	const [sortDesc, setSortDesc] = useState(props.column.isSortDescending() ? 'active' : 'inactive');
	const [sortNone, setSortNone] = useState(props.column.isSortNone() ? 'active' : 'inactive');
	const columnName = props.column.getColId();

	const setSort = () => {

		if (sortAsc === 'active') {
			props.setSort('desc');
		}
		else if (sortDesc === 'active') {
			props.setSort('asc');
		}
		else {
			if (['diagnosisScore', 'trialScore', 'publicationScore', 'overallScore'].includes(columnName)) {
				props.setSort('desc');
			}
			else {
				props.setSort('asc');
			}
		}
	}

	const onSortChanged = () => {
		setSortAsc(props.column.isSortAscending() ? 'active' : 'inactive');
		setSortDesc(props.column.isSortDescending() ? 'active' : 'inactive');
		setSortNone(!props.column.isSortAscending() && !props.column.isSortDescending() ? 'active' : 'inactive');
	}

	useEffect(() => {
		props.column.addEventListener('sortChanged', onSortChanged);
		onSortChanged();

		if (columnName === 'diagnosisScore') {
			props.setSort('desc');
		}
	}, []);

	return (
		<Grid className={classes.root} container direction='row' alignItems='center'>
			<Grid item>
				{sortAsc === 'active' &&
					<KeyboardArrowUpIcon />
				}
				{sortDesc === 'active' &&
					<KeyboardArrowDownIcon />
				}
			</Grid>
			<Grid item>
				<div className={classes.header} onClick={() => setSort()}>{props.displayName}</div>
			</Grid>
		</Grid >
	);
};

export const useColumnHeader = (displayName: string) => {

	return (params: IHeaderParams) => {

		const props = {
			...params,
			displayName
		};

		return (
			<ColumnHeader {...props} />
		);
	}
};

export default ColumnHeader;
