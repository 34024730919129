import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			border: '1px solid black',
			display: 'flex',
			flexDirection: 'column',
			margin: '10px',
			maxWidth: '420px',
			overflowY: 'scroll',
			padding: '0 5px 5px 5px'
		},
		modal: {

		},
		diagnosisBtn: {
			backgroundColor: '#9EB3C3',
			borderColor: '#344856',
			color: '#344856',
			fontSize: 16,
			fontWeight: 'bold',
			justifyContent: 'left',
			textDecoration: 'none',
			textTransform: 'none',
			marginBottom: '3px',
			minWidth: '200px',
			maxWidth: '400px',
			opacity: 1,
			'&[data-active="true"]': {
				backgroundColor: '#EBFCFF'
			}
		},
		search: {
			backgroundColor: '#FFFFFF',
			maxWidth: '400px',
			marginBottom: '10px',
			position: 'sticky',
			top: 0,
			zIndex: 99
		}
	}),
);
