import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {

		},
		cell: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			paddingBottom: '10px'
		},
		popover: {
			pointerEvents: 'none',
		},
		paper: {
			padding: theme.spacing(1),
		},
	}),
);