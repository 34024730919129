import { useMediaQuery, useTheme } from "@material-ui/core";
import { ColDef, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { useDoctorRenderer } from "./components/doctorRenderer.component";
import HospitalRenderer from "./components/hospitalRenderer.component";
import RegionRenderer from './components/regionRenderer.component';
import { useDiagnosisRenderer, useOverallRenderer, usePubsRenderer, useTrialsRenderer } from "./components/ratingRenderer.componant";
import { useColumnHeader } from './components/columnHeader.component';
import { useStyles } from "./Report.styles";
import { IDoctor } from "../data/DiagnosisData";


const floatFormatter = (params: ValueFormatterParams) => {

	const value: Number = params.value;

	if (value === 1 || value === 0) {
		return `${value}`;
	}
	else {
		return params.value.toPrecision(2);
	}
};

const defaultColDef = {
	resizable: true,
	sortable: true,
	autoHeight: true
}

const flexColumnDef = {
	...defaultColDef,
	flex: 1
}

export const useDoctorColumns = (onClick: (data: IDoctor) => void): ColDef[] => {

	const theme = useTheme();
	const classes = useStyles();
	const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

	return [
		{
			...flexColumnDef,
			field: 'lastName',
			headerName: 'Doctor (Last, First)',
			filter: true,
			floatingFilter: isMobile ? false : true,
			flex: 1,
			minWidth: 200,
			pinned: isMobile ? 'left' : '',
			filterValueGetter: (params: ValueGetterParams) => {
				const { data } = params;
				return `${data.firstName} ${data.lastName}`;
			},
			cellRendererFramework: useDoctorRenderer(onClick),
			headerComponentFramework: useColumnHeader('Doctor (Last, First)')
		},
		{
			...flexColumnDef,
			field: 'hospitalName',
			headerName: 'Hospital',
			filter: true,
			floatingFilter: isMobile ? false : true,
			flex: 1,
			minWidth: 200,
			filterValueGetter: (params: ValueGetterParams) => {
				const { data } = params;
				return `${data.hospitalName}, ${data.city}, ${data.state}`;
			},
			cellRendererFramework: HospitalRenderer,
			headerComponentFramework: useColumnHeader('Hospital')
		},
		{
			...defaultColDef,
			field: 'region',
			headerName: 'Region',
			filter: true,
			floatingFilter: isMobile ? false : true,
			width: 150,
			minWidth: 150,
			cellClass: classes.centerCell,
			cellRendererFramework: RegionRenderer,
			headerComponentFramework: useColumnHeader('Region')
		},
		{
			...defaultColDef,
			field: 'diagnosisScore',
			headerName: 'Diagnosis Expertise',
			headerTooltip: 'Led Clinical Trial and Publication Experience of Doctor vs Peers',
			width: 150,
			minWidth: 150,
			cellClass: classes.centerCell,
			valueFormatter: floatFormatter,
			cellRendererFramework: useDiagnosisRenderer,
			headerComponentFramework: useColumnHeader('Diagnosis Expertise')
		},
		{
			...defaultColDef,
			field: 'trialScore',
			headerName: 'Led Clinical Trials',
			headerTooltip: 'Led Clinical Trial Experience vs Peers',
			width: 150,
			minWidth: 150,
			cellClass: classes.centerCell,
			valueFormatter: floatFormatter,
			cellRendererFramework: useTrialsRenderer,
			headerComponentFramework: useColumnHeader('Led Clinical Trials')
		},
		{
			...defaultColDef,
			field: 'publicationScore',
			headerName: 'Published & Cited Research',
			headerTooltip: 'Overall Published Research vs Peers Within Diagnosis',
			width: 150,
			minWidth: 150,
			cellClass: classes.centerCell,
			valueFormatter: floatFormatter,
			cellRendererFramework: usePubsRenderer,
			headerComponentFramework: useColumnHeader('Published & Cited Research')
		},
	];
};

export const useHospitalColumns = (): ColDef[] => {

	const theme = useTheme();
	const classes = useStyles();
	const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

	return [
		{
			...flexColumnDef,
			field: 'name',
			headerName: 'Hospital',
			filter: true,
			floatingFilter: isMobile ? false : true,
			minWidth: 200,
			filterValueGetter: (params: ValueGetterParams) => {
				const { data } = params;
				return `${data.hospitalName}, ${data.city}, ${data.state}`;
			},
			cellRendererFramework: HospitalRenderer,
			headerComponentFramework: useColumnHeader('Hospital')
		},
		{
			...defaultColDef,
			field: 'region',
			headerName: 'Region',
			filter: true,
			floatingFilter: isMobile ? false : true,
			width: 150,
			minWidth: 150,
			cellClass: classes.centerCell,
			cellRendererFramework: RegionRenderer,
			headerComponentFramework: useColumnHeader('Region')
		},
		{
			...defaultColDef,
			field: 'diagnosisScore',
			headerName: 'Diagnosis Expertise',
			headerTooltip: 'Led Clinical Trial and Publications of Doctors at Hospital',
			filter: false,
			width: 150,
			minWidth: 150,
			cellClass: classes.centerCell,
			valueFormatter: floatFormatter,
			cellRendererFramework: useDiagnosisRenderer,
			headerComponentFramework: useColumnHeader('Diagnosis Expertise')
		},
		{
			...defaultColDef,
			field: 'overallScore',
			headerName: 'Overall Expertise',
			headerTooltip: 'Cumulative Cancer Expertise of Entire Hospital Across All Diagnoses',
			filter: false,
			width: 150,
			minWidth: 150,
			cellClass: classes.centerCell,
			valueFormatter: floatFormatter,
			cellRendererFramework: useOverallRenderer,
			headerComponentFramework: useColumnHeader('Overall Expertise')
		}
	];
};

